import React, {useState, useEffect} from 'react'
import './style.scss'
//import NaviContext from '../../../context/NaviContext'

import Intro from '../../../animations/Intro'

function Time(props) {
	//const naviContext = useContext(NaviContext)
	const [time,setTime] = useState()

	function getTime() {
		const date = new Date()
		let tiem = date.toLocaleTimeString('en-US', { timeZone: 'America/New_York', hour12: false, hour: '2-digit', minute: '2-digit', second : '2-digit' })
		setTime(tiem)
	}

	useEffect(() => {
		let interval = setInterval(function() {
      getTime()
    }, 1000)
    return () => clearInterval(interval)
	},[])



  return (
		<div className='time has-intro'>
			<Intro visible={true} in={{ fadeInTop: 1500 }} delayIn={1000} className="">
				<p>MIA // NY {time}</p>
			</Intro>
		</div>
  )
}

export default Time