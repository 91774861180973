import React, {useState, createContext, useEffect} from 'react'
import { useQuery, gql } from '@apollo/client'

const ApolloContext = createContext(false)

function ApolloContextProvider({children, location}) {
  // State for telling if the Apollo is active or not
	const [isLoading, setLoading] = useState(false)
	const [data, setData] = useState()

  const [nextQuery, setNextQuery] = useState(false)

  // Apollo navi query
  const { loading, error, data: apollo, fetchMore } = useQuery(APOLLO_QUERY, {
    variables: { after: null, afterF: null },
    notifyOnNetworkStatusChange: true,
  })

  //console.log('apollo', error, apollo)

  useEffect(() => {
		setLoading(loading)
		// If loaded rehydrate
		if(!loading && !error) {
			setData(apollo)
		}
  },[loading, error, nextQuery])


  const loadMore = () => {
    const { endCursor, hasNextPage } = data?.News.pageInfo
  
    if(hasNextPage) {
      nextQuery ? setNextQuery(false) : setNextQuery(true)
      fetchMore({
        variables: {after: endCursor},
        updateQuery: (prevResult, { fetchMoreResult }) => {
    
          fetchMoreResult.News.nodes = [
            ...prevResult.News.nodes,
            ...fetchMoreResult.News.nodes
          ];
          return fetchMoreResult;
        }
      })
    }
 
  }

  const loadMoreF = () => {
    const { endCursor, hasNextPage } = data?.NewsFeatured.pageInfo
  
    if(hasNextPage) {
      nextQuery ? setNextQuery(false) : setNextQuery(true)
      fetchMore({
        variables: {afterF: endCursor},
        updateQuery: (prevResult, { fetchMoreResult }) => {
    
          fetchMoreResult.NewsFeatured.nodes = [
            ...prevResult.NewsFeatured.nodes,
            ...fetchMoreResult.NewsFeatured.nodes
          ];
          return fetchMoreResult;
        }
      })
    }
 
  }

	return (
		<ApolloContext.Provider value={{
      isLoading,
			data,
      loadMore,
      loadMoreF,
    }}>
      {children}
    </ApolloContext.Provider>
	)
}

export default ApolloContext
export { ApolloContextProvider }

// WPGRAPHQL Query for rehydration, posts
const APOLLO_QUERY = gql`
query apolloQuery($after: String, $afterF: String)
{
  portfolioTypes {
    nodes {
      name
      slug
    }
  }
  academics: portfolios(first: 100) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      portfolioTypes {
        nodes {
          name
          slug
        }
      }
      portfolio {
        location
        website
        blocks {
          size
          type
          classes
          img {
            srcSet(size: LARGE)
            sourceUrl
          }
          video {
            mediaItemUrl
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  News: posts(first: 10, after: $after) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      news {
        blocks {
          size
          type
          classes
          img {
            srcSet(size: LARGE)
          }
          video {
            mediaItemUrl
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  NewsFeatured: posts(first: 5, after: $afterF) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      news {
        blocks {
          size
          type
          classes
          img {
            srcSet(size: LARGE)
          }
          video {
            mediaItemUrl
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`


// add_filter( 'register_post_type_args', function( $args, $post_type ) {

//   // Change this to the post type you are adding support for
// if ( 'academic' === $post_type ) { 
// $args['show_in_graphql'] = true;
// $args['graphql_single_name'] = 'academic';
// $args['graphql_plural_name'] = 'academics';
// }

// return $args;

// }, 10, 2 );
// add_filter( 'register_taxonomy_args', function( $args, $taxonomy ) {

//   // Change this to the post type you are adding support for
// if ( 'schools' === $taxonomy ) { 
// $args['show_in_graphql'] = true;
// $args['graphql_single_name'] = 'school';
// $args['graphql_plural_name'] = 'schools';
// }

// return $args;

// }, 10, 2 );