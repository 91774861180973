import React from 'react'
import './style.scss'
import { Link } from 'gatsby'
//import NaviContext from '../../../context/NaviContext'

import Intro from '../../../animations/Intro'
import { StaticImage } from "gatsby-plugin-image"

function Logo(props) {
	//const naviContext = useContext(NaviContext)

  return (
		<Link to="/" className='logo master-logo has-intro'>
			<Intro visible={true} in={{ bg: 700, fade: 500 }} className="">
				<div className="aspect-ratio">
					<StaticImage className="bg img-contain" src="logo.png" alt="Logo" />
				</div>
			</Intro>
		</Link>
  )
}

export default Logo