import React, {useContext} from 'react'
import '../EVG/css/_core.scss';

import Header from './Header'

import { Helmet } from 'react-helmet'
import { ParallaxProvider } from 'react-scroll-parallax'

import FsLightbox from 'fslightbox-react'
import FSLightBoxContext from '../context/FSLightBoxContext'

if (typeof window !== 'undefined') {

	// Set up pace js
	// window.paceOptions = {
	// 	className: 'dss-loader',
	// 	ajax: false, // ajax monitoring - disabled
	// 	restartOnPushState: false, // no restart on push state
	// 	document: false, // document ready state monitoring - disabled
	// 	eventLag: false, // monitoring of event loop lag, signaling js is being executed - disabled
	// 	elements: {
	// 		selectors: ['.hero', '.main-frontpage'] // looks for existance of this element
	// 	}
	// }

	// eslint-disable-next-line global-require
	require('smooth-scroll')('a[href*="#"]:not([href="#"])', {
		speed: 800,
		speedAsDuration: true,
		easing: 'easeInOutCubic',
		offset: window.innerHeight * 0.5
	})
}

function Layout(props) {
	const lightBoxContext = useContext(FSLightBoxContext)

  return (
  	<>

			<Helmet>
				{/* <script src="/js/pace/pace.min.js"></script> */}
				<link rel="preconnect" href="https://fonts.gstatic.com"/>
				<link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:ital@0;1&family=Roboto:ital@0;1&display=swap" rel="stylesheet"/> 
			</Helmet>

			<Header {...props.data} />
			
			<ParallaxProvider>
				{props.children}
			</ParallaxProvider>

			{lightBoxContext.sources ?
				<FsLightbox
					toggler={lightBoxContext.toggler}
					sources={lightBoxContext.sources}
					slide={lightBoxContext.slide}
					key={lightBoxContext.sources[0]}
					type={lightBoxContext.type}
					initialAnimation="scale-in-long"
					slideChangeAnimation="scale-in"
				/>
			: null }

  	</>
  )
}

export default Layout