import React, {useContext} from 'react'
import './style.scss'
import NaviContext from '../../context/NaviContext'
import { Transition } from 'react-transition-group'
import anime from 'animejs'

import Hamburger from './Hamburger'
import NaviPrimary from './Navi/Primary'
import SocialIcons from '../SocialIcons'
import Logo from './Logo'
import Time from './Time'

function Header(props) {
	const naviContext = useContext(NaviContext)

	// Animating fade in/out
	const baseDuration = 250
	const fadeInLogo = element => {
		const links = element.querySelectorAll('.nav-item, .social-icons a')
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [0, 1],
				translateY: ['-100%','0%'],
				duration: 500,
				easing: 'easeOutSine',
			})
			.add({
				targets: links,
				opacity: [0, 1],
				translateY: [-10, 0],
				duration: baseDuration,
				easing: 'easeInOutSine',
				delay: anime.stagger(50)
			}, `-=${baseDuration - 300}`)
	}
	const fadeOutLogo = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [1, 0],
				duration: baseDuration / 2,
				easing: 'linear'
			})
	}

	// Fire up expanded navi on hamburger activation alone
	const naviExpanded = naviContext.isHamburgerActive

  return (
		<header className={'c5 t master-header'}>

			{/* {naviContext.beforeHeaderBreakpoint ? 'true' :	
			naviContext.scrollingDirectionIsDown ? 'false' : 'true'} */}
			{/* {naviContext.windowSize?.mobile} */}
			{naviContext.windowSize?.width < 1024 ?
				<Hamburger baseDuration={baseDuration} />
			: null}

			<Transition
				in={naviExpanded || naviContext.windowSize?.width > 1024}
				timeout={baseDuration}
				appear={true}
				onEntering={fadeInLogo}
				onExiting={fadeOutLogo}
				mountOnEnter
				unmountOnExit
			>
				<div className={`master-navi c5`}>
					{naviContext.windowSize?.width > 1024 ? 
						<>
							<Logo />
							<Time />
						</>
					: null}
					<NaviPrimary { ...props } baseDuration={baseDuration} />
					{/* <SocialIcons /> */}
				</div>
			</Transition>
		</header>
  )
}

export default Header